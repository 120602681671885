import { type PageFragment } from "~/graphql/generated";

export const useUtils = () => {
  /**
   *  Split the pages array into chunks of 2 (pages per view), starting with a blank page
   * @param {PageFragment[]} pages
   * @returns The pages array split into chunks of 2, starting with a null for the first array entry
   */
  const pagesToSpreads = (pages: PageFragment[]) => {
    return computed(() => {
      const pagesArray: (PageFragment | null)[] = [...pages];

      // Add a blank page to the start of the array because the first left page is always empty/ads
      pagesArray.unshift(null);

      const spreads: Array<[PageFragment | null, PageFragment | null]> = [];

      // Loop over all pages and add them to the spreads array
      for (let i = 0; i < pagesArray.length; i += 2) {
        spreads.push([pagesArray[i], pagesArray[i + 1]]);
      }

      // Return only spreads that have at least one page
      return spreads.filter((spread) => spread[0] || spread[1]);
    });
  };

  const currencyFormatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  return { pagesToSpreads, currencyFormatter };
};
